@import "./variable.scss";

.ant-drawer-wrapper-body {
    .ant-drawer-header {
        background-color: $color_theme;
    }
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: $color_button_hover;
    font-weight: bold;
}

button.button-white {
    background-color: $color_button;
}

button.button-white.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: $color_button_hover;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.ant-table th.ant-table-selection-column {
    width: 15%;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem 0.5rem;
}

.ant-popconfirm-buttons > button {
    color: white;

    &:nth-child(1) {
        border-color: $color_button;
    }

    &:nth-child(1):hover {
        color: white !important;
        font-weight: bold;
        background-color: $color_button_hover;
    }

    // &:nth-child(2) {
    //     background-color: #faad14;
    // }
}

th.ant-table-cell.ant-table-selection-column {
    width: 50px;
}

.anticon.cursor-not-allowed {
    cursor: not-allowed;
}

.form-label-text-left {
    &.label-pl-2 {
        padding-left: 0.25rem;
        margin-left: 0.5rem;
    }

    .ant-form-item-label {
        text-align: left;
    }
}

.radio-flex-row {
    .ant-form-item-row {
        flex-direction: row;
        align-items: center;

        .ant-form-item-label {
            padding: 0px 0px;
        }

        .ant-form-item-control {
            text-align: right;

            label.ant-radio-wrapper:last-child {
                margin-inline-end: 0px;

                span:last-child {
                    padding-right: 0px;
                }
            }
        }
    }
}

.ant-form-item-row {
    .ant-form-item-label {
        label {
            font-weight: bold;
        }
    }
}

.form-no-bold {
    .ant-form-item-row {
        .ant-form-item-label {
            label {
                font-weight: 400;
            }
        }
    }
}

.ant-spin-nested-loading > div > .ant-spin {
    z-index: 1001;
    width: 100vw;
    height: 100vh;
    max-height: none;
}

.ant-select-dropdown {
    .create-transition-code {
        width: 100%;
        padding: 0 8px 4px;

        .ant-space-item:first-child {
            width: 100%;
        }
    }
}

.ant-table-filter-dropdown-btns {
    .ant-btn-link.ant-btn-sm {
        color: white;
    }

    .ant-btn-link.ant-btn-sm:hover {
        color: white !important;
        font-weight: bold;
        background-color: $color_button_hover;
    }
}

.ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.homepage-table {
    .ant-table-container {
        .ant-table-body {
            overflow-y: auto !important;
        }
    }

    .ant-table-cell-scrollbar {
        display: none;
    }
}

.no-padding {
    .ant-input-group-addon {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.form-web-search {
    .ant-form-item {
        .ant-form-item-label {
            label {
                width: 120px;
            }
        }
    }
}
