@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./global.scss";
@import "./antd_custom.scss";
@import "./variable.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  background-color: $color_button;
}

