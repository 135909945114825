@import "./variable.scss";
//Login page css

.ant-form-item-required::before {
    display: none;
    visibility: hidden;
}

.ant-form-item-required::after {
    display: none;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    padding: 30px;
    max-width: 400px;
    width: 100%;
    /* height: 60vh; */
}

.img-logo {
    max-width: 200px;
    margin-bottom: 20px;
}

//Homepage css
.home-container {
    background-color: $color_theme;
}

.topbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-icon {
    margin-right: auto;
    margin-left: 10px;
}


//Change password modal css

.change-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.change-password-form-container {
    padding: 20px;
    width: 100%;
    max-width: 400px;
    /* height: 60vh; */
}